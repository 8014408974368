<template>
  <div class="container-projet">
    <ListProjects></ListProjects>
  </div>
</template>

<script>
import ListProjects from "./ListProjects";
export default {
  name: "Projet",
  components: {ListProjects}
}
</script>

<style lang="scss" scoped>

</style>